<template>
  <div class="SiteVersionElement" :class="{ _current: isCurrent, _hovered: isHovered }" @mouseenter="isHovered = true"
    @mouseleave="isHovered = false">
    <div class="SiteVersionElement__content">
      <div class="SiteVersionElement__label-col d-none d-md-block">
        <div class="SiteVersionElement__label">
          <the-icon icon="shape" class="SiteVersionElement__label-shape" :class="{ _secondary: !isCurrent }" />
          <span class="SiteVersionElement__label-text">
            {{ labelText }}
            <v-icon class="SiteVersionElement__label-icon" color="y1" v-if="isCurrent">mdi-check</v-icon>
          </span>
        </div>
      </div>

      <div class="SiteVersionElement__row">
        <div class="SiteVersionElement__info-main">
          <div class="SiteVersionElement__info-main-header">
            <h6 class="SiteVersionElement__info-main-title">
              Version #{{ version.scopedId }}
            </h6>
            <v-tooltip top v-if="!uploading && deploying" content-class="SiteVersionElement__tooltip">
              <template #activator="{ on, attrs }">
                <v-btn class="SiteVersionElement__trigger-btn" @click="openConsoleModal(messages)" color="transparent"
                  v-bind="attrs" v-on="on" depressed>
                  <the-icon icon="console" class="SiteVersionElement__console-icon" />
                </v-btn>
              </template>
              <span>View Logs</span>
            </v-tooltip>
          </div>
          <div v-if="!deploying" class="SiteVersionElement__info-pale-text">
            Uploaded {{ createdAt }}
          </div>
          <div v-else-if="uploading" class="pale-text">Uploading</div>
          <div class="SiteVersionElement__progress" v-else>
            <v-progress-linear height="4" color="y1" background-color="n5" rounded class="SiteVersionElement__progress"
              :value="version.percentDeployed"></v-progress-linear>
          </div>
        </div>

        <div class="SiteVersionElement__info-description">
          <h6 class="SiteVersionElement__info-description-title">
            <div v-if="!editing" @click="editing = true">
              <span v-if="version.description.length > 0">
                {{ version.description }}
              </span>
              <span class="SiteVersionElement__add_description_block" v-else>
                <small>Click Here to Add description</small>
              </span>
            </div>
            <div v-else-if="editing">
              <v-text-field v-on:keyup.enter="saveDescription" autofocus v-model="newDesc" class="mt-1" placeholder=""
                hide-details="auto" filled dark dense />
              <span class="mt-1">
                <v-btn small @click="saveDescription" color="y1">
                  Save
                </v-btn>
                <v-btn small @click="cancelDescription" color="n5" class="ml-2" dark>
                  Cancel
                </v-btn>
              </span>
            </div>
          </h6>
          <v-tooltip v-model="showingChanges" v-if="!editing" top>
            <template #activator="{ on }">
              <p v-on="on" @click="openDiffModal()" class="SiteVersionElement__info-description-changes">
                {{ briefChanges }}
              </p>
            </template>
            <div>
              <div v-if="!!version.diff.modified.length">
                <v-divider class="my-2" />
                <div class="pale-text SiteVersionElement__diff_header mb-2">
                  {{ version.diff.modified.length }} MODIFIED
                </div>
                <div v-for="file in limitedModified" :key="file" class="d-flex align-center">
                  <img class="file-icon" src="./files-icon-modif.png" />
                  <span class="ml-1">{{ file }}</span>
                </div>
                <div class="SiteVersionElement__file_name" v-if="version.diff.modified > 4">
                  + {{ version.diff.modified.length - 4 }} more
                </div>
              </div>
              <div v-if="!!version.diff.added.length">
                <v-divider class="my-2" />
                <div class="pale-text SiteVersionElement__diff_header mb-2">
                  {{ version.diff.added.length }} ADDED
                </div>
                <div v-for="file in limitedAdded" :key="file" class="d-flex align-center">
                  <v-icon class="SiteVersionElement__file added"> mdi-file-outline </v-icon>
                  <span class="ml-1 SiteVersionElement__file_name">{{ file }}</span>
                </div>
                <div class="SiteVersionElement__file_name" v-if="version.diff.added.length > 4">
                  + {{ version.diff.added.length - 4 }} more
                </div>
              </div>
              <div v-if="!!version.diff.deleted.length">
                <v-divider class="my-2" />
                <div class="pale-text SiteVersionElement__diff_header mb-2">
                  {{ version.diff.deleted.length }} DELETED
                </div>
                <div v-for="file in limitedRemoved" :key="file" class="d-flex align-center">
                  <v-icon class="SiteVersionElement__file removed"> mdi-file-outline </v-icon>
                  <span class="ml-1 SiteVersionElement__file_name"> {{ file }} </span>
                </div>
                <div class="SiteVersionElement__file_name" v-if="version.diff.deleted.length > 4">
                  + {{ version.diff.deleted.length - 4 }} more
                </div>
              </div>
            </div>
          </v-tooltip>

          <!--  -->
        </div>

        <div class="SiteVersionElement__end-buttons" :class="{ _active: isCurrent }">
          <v-tooltip top content-class="SiteVersionElement__tooltip" v-if="version.unreadComments == 0">
            <template #activator="{ on, attrs }">
              <v-btn class="SiteVersionElement__button" x-small @click="openCommentsModal" icon dark 
                v-bind="attrs" v-on="on">
                <the-icon class="SiteVersionElement__end-buttons-icon" icon="comment" />
              </v-btn>
            </template>
            <span>Add Comment</span>
          </v-tooltip>
          <v-tooltip top v-else content-class="SiteVersionElement__tooltip">
            <template #activator="{ on, attrs }">
              <v-btn class="SiteVersionElement__button" x-small @click="openCommentsModal" icon dark v-bind="attrs"
                v-on="on">
                <the-icon icon="comment-with-dot" class="SiteVersionElement__comment-with-dot" />
              </v-btn>
            </template>
            <span>+ {{ version.unreadComments }} {{ commentsCountText }}</span>
          </v-tooltip>
          <v-tooltip top content-class="SiteVersionElement__tooltip">
            <template #activator="{ on, attrs }">
              <v-btn @click="download" class="SiteVersionElement__button" x-small icon dark v-bind="attrs" v-on="on">
                <the-icon class="SiteVersionElement__end-buttons-icon" icon="download" />
              </v-btn>
            </template>
            <span>Download Archive</span>
          </v-tooltip>
          <v-btn @click="remove" v-if="!isCurrent" class="SiteVersionElement__button" x-small icon dark>
            <the-icon class="SiteVersionElement__end-buttons-icon" icon="trash" />
          </v-btn>
        </div>
      </div>
    </div>

    <div class="SiteVersionElement__line">
      <span @click="deploy" class="SiteVersionElement__line-btn" :class="{ _active: isCurrent }" />
    </div>
  </div>
</template>

<script>
import { getRelativeTime } from "@/utils/common";
import { Version } from "@/models/Version";
import { MODAL_ALERT, MODAL_VERSION_DIFF, MODAL_FORGE_CONSOLE, MODAL_COMMENTS } from "@/components/Modals";
export default {
  components: {},
  name: "SiteVersionElement",

  props: {
    version: Version,
    arrayType: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      showingChanges: false,
      shapeSvg: require("@/assets/svg/Shape.svg"),
      isHovered: false,
      editing: false,
      newDesc: this.version.description
    };
  },

  computed: {
    briefChanges() {
      let res = "";
      if (this.version.diff.modified.length)
        res += this.version.diff.modified.length + " changed, ";
      if (this.version.diff.added.length)
        res += this.version.diff.added.length + " added, ";
      if (this.version.diff.deleted.length)
        res += this.version.diff.deleted.length + " deleted, ";
      return res.substr(0, res.length - 2);
    },
    createdAt() {
      return getRelativeTime(this.version.createdAt).toLowerCase();
    },
    limitedAdded() {
      return this.version.diff.added.slice(0, 4);
    },
    limitedRemoved() {
      return this.version.diff.deleted.slice(0, 4);
    },
    limitedModified() {
      return this.version.diff.modified.slice(0, 4);
    },
    commentsCountText() {
      return this.version.unreadComments == 1 ? 'comment' : 'comments'
    },
    isCurrent() {
      if (this.arrayType == 0)
        return this.activeSite.currentVersionId == this.version.id;
      else
        return (
          this.$store.state.parse_cloud.current.current_version_id ==
          this.version.id
        );
    },
    isNewerThanCurrent() {
      let current_version = null;
      if (this.arrayType == 0)
        current_version = this.$store.getters["versions/activeVersion"];
      else current_version = this.$store.getters["versions/activeParseVersion"];
      return current_version == undefined ? false : current_version.scopedId < this.version.scopedId;
    },
    labelText() {
      return this.isCurrent
        ? "Live"
        : this.isNewerThanCurrent
          ? "Deploy?"
          : "Roll back ?";
    },
    deploying() {
      return this.isCurrent && this.version.percentDeployed < 100;
    },
    uploading() {
      return this.isCurrent && this.version.percentDeployed == 0;
    },
    messages() {
      return this.version.messages;
    },
  },

  methods: {
    deploy() {
      if (this.arrayType == 0)
        this.$store
          .dispatch("sites/setCurrentVersion", this.version.id)
          .catch((e) => {
            console.log(e);
          });
      else
        this.$store.dispatch('parse_cloud/update', {
          id: this.$store.state.parse_cloud.current.id,
          parse_custom_code: {
            current_version_id: this.version.id
          }
        })
    },
    saveDescription() {
      let params = {
        id: this.version.id,
        version: {
          description: this.newDesc
        }
      }
      this.$store.dispatch("versions/updateDesc", params)
        .then(() => {
          this.editing = false;
        })
        .catch((e) => {
          console.log(e);
          this.editing = false;
        })
    },
    openDiffModal() {
      this.$store.commit("application/openModal", {
        component: MODAL_VERSION_DIFF,
        props: {
          version: this.version,
        },
      });
    },
    cancelDescription() {
      this.newDesc = this.version.description;
      this.editing = false;
    },
    getFileLine(file) {
      const ind = file.lastIndexOf("/");
      if (ind == -1) return file;

      const path = file.substr(0, ind);
      const name = file.substr(ind);
      return (
        '<span style="opacity: .6">' + path + "</span><span>" + name + "</span>"
      );
    },
    openConsoleModal() {
      this.$store.commit("application/openModal", {
        component: MODAL_FORGE_CONSOLE,
        props: {
          version: this.version,
          deployed: !this.uploading && !this.deploying,
        },
      });
    },
    openCommentsModal() {
      this.$store.commit("application/openModal", {
        component: MODAL_COMMENTS,
        props: {
          version: this.version,
          cloudCode: this.arrayType == 1
        }
      })
    },
    forgeConsoleIcon() {
      return require("@/assets/console-deploying.svg");
    },
    remove() {
      const context = this;
      let text = "";
      if (this.arrayType == 0)
        text = `You are going to remove <b>Version ${this.version.scopedId} (${this.version.description})</b> of <b>${this.activeSite.url}</b>. Are you sure?`;
      else
        text = `You are going to remove <b>Version ${this.version.scopedId}</b>. Are you sure?`;
      this.$store.commit("application/openModal", {
        component: MODAL_ALERT,
        props: {
          title: "Version deletion",
          text: text,
          actionBtnLabel: "Remove",
          showCancelBtn: true,
          action: () => {
            this.$store.dispatch("versions/delete", context.version.id);
          },
        },
      });
    },
    download() {
      this.api.Version.download({ id: this.version.id }).then((res) => {
        window.open(res.body.version_url, "_blank");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$style: SiteVersionElement;

.v-tooltip__content {
  opacity: 1 !important;
  background: $N5;
  border-radius: 4px;
}

.#{$style} {
  position: relative;
  width: 100%;

  &:not(.#{$style}._current) {
    .#{$style}__label {
      visibility: hidden;
    }
  }

  &:not(.#{$style}._current):hover {
    .#{$style}__label {
      visibility: visible;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    display: flex;
  }

  &__add_description_block {
    cursor: pointer;
  }

  &__label-col {
    flex: 1 1 200px;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    height: 40px;
    width: 148px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label-shape {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    fill: rgba($Y1, 0.2);
    z-index: 0;

    &._secondary {
      fill: rgba($N5, 0.5);
    }
  }

  &__label-text {
    color: $Y1;
    font-weight: 500;
    font-size: $H14;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &__diff_header {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.24px;
  }

  &__file {
    width: 14px;
    height: 14px;
    font-size: 14px;

    &.removed {
      color: $R1;
    }
  }

  &__file_name {
    font-size: 12px;
    color: $N8;
  }

  &__label-icon {
    font-size: 20px;
  }

  &__line {
    position: absolute;
    top: 0;
    left: 200px;
    width: 1px;
    height: 100%;
    background: $N5;
  }

  &__line-btn {
    position: absolute;
    left: -8px;
    top: 32px;
    width: 16px;
    height: 16px;
    background: $N5;
    border: 2px solid $N3;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.2s ease;

    &._active {
      border-color: $Y1;
    }

    &:not(._active):hover {
      border-color: rgba($Y1, 0.2);
    }
  }

  &__info-main {
    width: 40%;
    padding: 0 40px 0 0;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $N5;
  }

  &__info-main-header {
    display: flex;
    align-items: center;
  }

  &__info-main-title {
    color: $white;
    font-size: $H16;
    font-weight: 500;
    margin: 0;
  }

  &__console-icon {
    fill: $white;
  }

  &__trigger-btn {
    &.v-btn:not(.v-btn--round).v-size--default {
      padding: 0;
      min-width: 30px;
      margin-left: 8px;
    }
  }

  &__tooltip {
    background: $white;
    color: $N5;
    font-weight: 500;
    font-size: $H12;
  }

  &__info-pale-text {
    color: $N3;
    font-size: $H14;
    font-weight: 400;
    margin: 0;
  }

  &__progress {
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__row {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
    padding: 0 26px;
  }

  &__info-description {
    width: 40%;
    padding: 0 40px 0 0;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $N5;
  }

  &__info-description-title {
    color: $white;
    font-size: $H16;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__info-description-changes {
    color: $N2;
    font-size: $H14;
    font-weight: 400;
    margin: 0;
    cursor: pointer;

    &:hover {
      color: $N3;
    }
  }

  &__end-buttons {
    padding: 0;
    height: 78px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid $N5;
    flex: 1;

    &._active {
      .#{$style}__end-buttons-icon {
        color: $N2;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .#{$style}__end-buttons-icon {
        color: $Y1;
      }
    }
  }

  &__end-buttons-label {
    color: $N5;
    font-size: $H14;
    font-weight: 500;
    margin-right: 10px;
    display: inline-block;
    white-space: nowrap;
  }

  &__end-buttons-icon {
    color: $N5;
  }

  &__comment-with-dot {
    margin-left: 8px;
  }

  @media screen and (max-width: 600px) {
    & {
      margin-bottom: 10px;
      border: 1px solid transparent;
    }

    &._current {
      border: 1px solid $Y1;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 5px;
    }

    &__row {
      flex-direction: column;
      padding-left: 35px;
      padding-right: 5px;
    }

    &__info-main {
      display: block;
      width: 100%;
      padding-right: 0px;
      height: auto;
      border-bottom: none;
    }

    &__info-description {
      width: 100%;
      height: auto;
      margin-top: 5px;
      margin-bottom: 5px;
      border-bottom: none;
    }

    &__end-buttons {
      border-bottom: none;
    }

    &__line {
      left: 15px;
    }

    &__line-btn {
      top: 50%;
      margin-top: -8px;
    }
  }
}
</style>

<template>
  <v-container>
    <v-card id="audit-card" flat>
      <v-card-title>Audit Logs</v-card-title>
      <v-card-text id="audit-card-text">
        <div class="audit-logs-container">
          <v-data-table
            :headers="headers"
            :items="auditLogs"
            class="elevation-1"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template v-slot:item.description="{ item }">
              <div>
                <div>{{ item.description }}</div>
                <div class="subtext">{{ item.subtext }}</div>
              </div>
            </template>
          </v-data-table>
          <div class="overlay">
            <div class="overlay-content">
              <h2>This is an Enterprise feature</h2>
              <p>Upgrade to Enterprise to enable Audit Logs</p>
              <spark-button @click="upgradeToEnterprise"
                >Contact Sales</spark-button
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SparkButton from "../_Common/SparkButton.vue";

export default {
  name: "OrganisationAudit",
  components: {
    SparkButton,
  },
  data() {
    return {
      headers: [
        { text: "", value: "icon", width: "50px" },
        { text: "Description", value: "description" },
      ],
      auditLogs: [
        {
          icon: "mdi-account-remove",
          description: "Removed a user from the Organisation",
          subtext: "by John Doe on Jul 26 at 2:30pm",
        },
        {
          icon: "mdi-github",
          description:
            "Changed deployment method from Drag & Drop to Github on mysite.getforge.com",
          subtext: "by Jane Smith on Jul 25 at 4:00pm",
        },
        {
          icon: "mdi-account-plus",
          description: "Added a new user to the Organisation",
          subtext: "by John Doe on Jul 24 at 10:15am",
        },
        {
          icon: "mdi-lock",
          description: "Changed user role from Member to Admin",
          subtext: "by Jane Smith on Jul 23 at 11:00am",
        },
        {
          icon: "mdi-database",
          description: "Updated environment variables for mysite.getforge.com",
          subtext: "by John Doe on Jul 22 at 9:30am",
        },
        {
          icon: "mdi-application",
          description: "Added a new Forge App to mysite.getforge.com",
          subtext: "by Jane Smith on Jul 21 at 2:45pm",
        },
        {
          icon: "mdi-delete",
          description: "Removed a site mysite.getforge.com",
          subtext: "by John Doe on Jul 20 at 4:00pm",
        },
        {
          icon: "mdi-web",
          description: "Created a new site newsite.getforge.com",
          subtext: "by Jane Smith on Jul 19 at 3:15pm",
        },
        {
          icon: "mdi-git",
          description: "Added a Forge Plugin to mysite.getforge.com",
          subtext: "by John Doe on Jul 18 at 1:45pm",
        },
        {
          icon: "mdi-cogs",
          description: "Changed site settings for mysite.getforge.com",
          subtext: "by Jane Smith on Jul 17 at 10:00am",
        },
      ],
    };
  },
  methods: {
    upgradeToEnterprise() {
      // Handle the upgrade action here
      console.log("Upgrade to Enterprise clicked");
    },
  },
};
</script>

<style scoped>
.audit-logs-container {
  position: relative;
}
#audit-card {
  background: transparent !important;
}
#audit-card-text {
  background: transparent !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #181729a1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}
.overlay-content {
  text-align: center;
  width: 450px;
  padding: 30px;
  background-color: #181729;
  border-radius: 10px;
  border: 1px solid #25243d;
}
.overlay-content h2 {
  margin-bottom: 16px;
}
</style>

import store from '@/store';

function updateFavicons(path) {
  const baseURL = window.location.origin + '/favicon_io/';
  let faviconPaths = {};
  const cacheBuster = new Date().getTime();

  if (path.startsWith('/publisher/')) {
    faviconPaths = {
      'favicon': baseURL + 'publisher-favicon.ico',
      'apple-touch-icon': baseURL + 'publisher-apple-touch-icon.png',
      'favicon-32x32': baseURL + 'publisher-favicon-32x32.png',
      'favicon-16x16': baseURL + 'publisher-favicon-16x16.png',
      'manifest': baseURL + 'publisher-site.webmanifest'
    };
  } else {
    faviconPaths = {
      'favicon': baseURL + 'favicon.ico',
      'apple-touch-icon': baseURL + 'apple-touch-icon.png',
      'favicon-32x32': baseURL + 'favicon-32x32.png',
      'favicon-16x16': baseURL + 'favicon-16x16.png',
      'manifest': baseURL + 'site.webmanifest'
    };
  }

  document.getElementById('dynamic-favicon').href = faviconPaths['favicon'] + '?v=' + cacheBuster;
  document.getElementById('dynamic-apple-touch-icon').href = faviconPaths['apple-touch-icon'] + '?v=' + cacheBuster;
  document.getElementById('dynamic-favicon-32x32').href = faviconPaths['favicon-32x32'] + '?v=' + cacheBuster;
  document.getElementById('dynamic-favicon-16x16').href = faviconPaths['favicon-16x16'] + '?v=' + cacheBuster;
  document.getElementById('dynamic-manifest').href = faviconPaths['manifest'] + '?v=' + cacheBuster;
}

function updateFaviconHook(router) {
  router.afterEach((to, from) => {
    updateFavicons(to.path);
  });
}

function checkAuth(router) {
  router.beforeEach(async (to, from, next) => {
    if (!store.state.user.current && !store.state.application.tryLoad) {
      try {
        await store.dispatch('application/load');
      } catch (error) {
        if (!to.matched.some(m => m.meta.requiresAuth) )
          next();
        else
          next({
            name: 'login',
            query: { redirect: to.fullPath }
          });
        return;
      }
    }

    if (!to.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.user.current && to.meta.isAuth)
        next({name: 'dashboard'});
      else
        next();
    } else {
      if (store.state.user.current)
        next();
      else
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        });
    }
  });
}

function reset404(router) {
  router.beforeEach((to, from, next) => {
    store.commit('application/setShowing404', to.name == '404');
    next();
  });
}

function setActiveSite(router) {
  router.beforeEach((to, from, next) => {
    if (to.matched[0] && to.matched[0].name == 'site') {
      store.dispatch('sites/setActive', to.params.id);
      if (!store.state.sites.active) {
        store.commit('application/setShowing404', true);
        return;
      }
    }

    next();
  });
}

function setActiveProject(router) {
  router.beforeEach((to, from, next) => {
    if (to.matched[0] && to.matched[0].name == 'project') {
      store.dispatch('projects/setActive', to.params.id);
      if (!store.state.projects.active) {
        store.commit('application/setShowing404', true);
        return;
      }
    }

    next();
  });
}

const hooks = [checkAuth, reset404, setActiveSite, setActiveProject, updateFaviconHook];
export default hooks;

<template>
  <div class="DashboardSectionWrapper">
    <v-row justify="space-between" class="DashboardSectionWrapper__head">
      <v-col cols="auto">
        <h4 class="DashboardSectionWrapper__title">{{ title }}</h4>
      </v-col>
      <v-col cols="auto" v-if="isButtonShown">
        <!-- <v-btn
          @click="$emit('on-show-all-click')"
          class="DashboardSectionWrapper__btn"
          small
          color="n5"
          >show all</v-btn
        > -->
      </v-col>
    </v-row>
    <slot />
  </div>
</template>
<script>
export default {
  name: "DashboardSectionWrapper",
  props: {
    title: {
      type: String,
      required: true,
    },
    isButtonShown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
$style: DashboardSectionWrapper;
.#{$style} {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
  }
  &__head {
    margin-bottom: 10px;
    flex: 0;
  }
  &__btn {
    &.v-btn {
      color: $Y1;
      font-size: $H10;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
</style>
